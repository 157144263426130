<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[17]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">


            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[1]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/agency/management" class="menu_drops">
                  {{$store.state.language.lang[10]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/central/office" class="menu_drops">
                  {{$store.state.language.lang[9]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/structure" class="menu_drops">
                  {{$store.state.language.lang[11]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/council" class="menu_drops">
                  {{$store.state.language.lang[12]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/territorial" class="menu_drops">
                  {{$store.state.language.lang[13]}}
                </router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/regulations" class="menu_drops">
                  {{$store.state.language.lang[15]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/organization" class="menu_drops">
                  {{$store.state.language.lang[16]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/activity" class="menu_drops">
                  {{$store.state.language.lang[17]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/museum" class="menu_drops">
                  {{$store.state.language.lang[18]}}
                </router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/vacancy" class="menu_drops">
                  {{$store.state.language.lang[19]}}
                </router-link>
              </div>

            </div>
          </div>
          <div class="col-md-9">
            <div class="m_body_title">
              <h2>
                {{$store.state.language.lang[17]}}</h2>
            </div>
           <div v-for="(item,index) in $store.state.about" :key="index">
             <div class="activety_description" v-html="item.about">
             </div>
           </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";

export default {
  components: {
    Footer,
    Navbar
  },

  data() {
    return {
      isActive_m: false
    }
  },
  mounted() {
    this.$store.dispatch('about')
  },
  methods: {
    open_modal() {

    }
  }
}
</script>
